import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import { GetListParams, GetListResult, GetManyParams, GetManyResult, GetOneParams, GetOneResult } from "react-admin";
import gql from "graphql-tag";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
import moment from "moment";
import Mbaas from "./Mbaas";
export class PurchaseLogsProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(resource: string, params: GetListParams): Promise<GetListResult> {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    // filterArray.push({
    //   grapari: {
    //     _eq: params.filter.grapariCountry,
    //   },
    // });
    filterArray.push({
      is_grapari: {
        _eq: params.filter.isGrapari,
      },
      is_mvno: {
        _eq: params.filter.isMvno,
      },
    });
    if (params.filter.grapariCountry) {
      filterArray.push({
        grapari_country: {
          _eq: params.filter.grapariCountry,
        },
      });
    }
    if (params.filter.q) {
      filterArray.push({
        user_id_data: {
          name: {
            _ilike: `%${params.filter.q}%`,
          },
        },
      });
    }
    if (params.filter.dateGte || params.filter.dateLte) {
      filterArray.push({
        created_at: {
          _gte: moment(params.filter.dateGte).startOf("day").toISOString(),
        },
      });
      filterArray.push({
        created_at: {
          _lte: moment(params.filter.dateLte).endOf("day").toISOString(),
        },
      });
    }
    if (params.filter.order_id) {
      filterArray.push({
        order_id: {
          _ilike: `%${params.filter.order_id}%`,
        },
      });
    }
    if (params.filter.purchase_id) {
      filterArray.push({
        purchase_id: {
          _ilike: `%${params.filter.purchase_id}%`,
        },
      });
    }
    if (params.filter.product_catalog_id) {
      filterArray.push({
        product_catalog_id: {
          _eq: params.filter.product_catalog_id,
        },
      });
    }
    if (params.filter.payment_id) {
      filterArray.push({
        payment_id: {
          _ilike: `%${params.filter.payment_id}%`,
        },
      });
    }
    if (params.filter.bundle_id) {
      filterArray.push({
        bundle_id: {
          _ilike: `%${params.filter.bundle_id}%`,
        },
      });
    }
    if (params.filter.target_detail) {
      filterArray.push({
        target_detail: {
          _ilike: `%${params.filter.target_detail}%`,
        },
      });
    }
    if (params.filter.status) {
      filterArray.push({
        status: {
          _eq: params.filter.status,
        },
      });
    }
    if (params.filter.user_id) {
      filterArray.push({
        user_id: {
          _eq: params.filter.user_id,
        },
      });
    }
    if (params.filter.user_country) {
      filterArray.push({
        _or: [
          { country: { _eq: params.filter.user_country } },
          { user_id_data: { country: { _eq: params.filter.user_country } } }
        ],
      });
    }
    if (filterArray.length > 0) {
      if (params.filter.q) {
        where._or = filterArray;
      } else {
        where._and = filterArray;
      }
    }
    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;
    const args = buildArgs({
      // @ts-ignore
      order: order || "DESC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });

    if (typeof params.filter.isBonus === "boolean") {
      filterArray.push({
        main_purchase_log_id: {
          _is_null: !params.filter.isBonus,
        },
      });
    }

    const queryResult = await this.client.gql.query({
      query: gql`
      query purchaseLogsList($where:PurchaseLogsWhereInput) {
        allPurchaseLogsList(
          where: $where, ${args}
          ){
          data{
            id
            created_at
            order_id
            purchase_id
            payment_id
            bundle_id
            product_catalog_id
            flashsale_id
            main_purchase_log_id
            user_id_data {
              name
              phone
            }
            product_catalog_id_data {
              label
              item_key
              item_group
            }
            selling_price
            target_detail
            status
            histories
            product_snapshot
            currency_snapshot
            remark
            is_mvno
            is_grapari
            grapari_country
            mvno_deduct_id
            mvno_is_refunded
            reward_log_id
          }
          count
        }
      }
      `,
      variables: {
        where,
      },
    });

    if (params.filter.q) {
      const userNameArray: any[] = [];
      queryResult?.allPurchaseLogsList?.data.forEach((x: any) => {
        userNameArray.push({
          id: x.user_id_data.name,
          name: x.user_id_data.name,
        });
      });
      const userNameArrayFiltered = userNameArray.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
      return {
        data: userNameArrayFiltered,
        total: userNameArrayFiltered.length,
      };
    }

    return {
      data: queryResult?.allPurchaseLogsList?.data.map((x: any) => {
        return { ...x, name: x.user_id_data.name };
      }),
      total: queryResult?.allPurchaseLogsList?.count,
    };
  }

  async getMany(resource: string, params: GetManyParams): Promise<GetManyResult<any>> {
    console.log({ method: "getMany", resource, params });
    const where: Record<string, any> = {};
    if (params.ids) {
      where._or = [
        {
          user_id_data: {
            name: {
              _in: params.ids,
            },
          },
        },
      ];
    }
    const queryResult = await this.client.gql.query({
      query: gql`
        query purchaseLogsList($where: PurchaseLogsWhereInput) {
          allPurchaseLogsList(where: $where) {
            data {
              id
              created_at
              order_id
              purchase_id
              product_catalog_id
              flashsale_id
              user_id_data {
                name
                phone
              }
              product_catalog_id_data {
                label
              }
              selling_price
              target_detail
              status
              histories
              product_snapshot
              currency_snapshot
              remark
              mvno_deduct_id
              mvno_is_refunded
              reward_log_id
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });
    console.log(queryResult);
    return {
      data: queryResult?.allPurchaseLogsList?.data.map((x: any) => {
        return { ...x, name: x.user_id_data.name };
      }),
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    console.log({ method: "getOne", resource, params });

    const queryResult = await this.client.gql.query({
      query: gql`
        query aPurchaseLog($id: UUID) {
          getPurchaseLogsById(id: $id) {
            id
            created_at
            order_id
            purchase_id
            product_catalog_id
            flashsale_id
            user_id_data {
              name
              phone
              country
            }
            product_catalog_id_data {
              label
            }
            selling_price
            target_detail
            status
            histories
            product_snapshot
            currency_snapshot
            remark
            mvno_deduct_id
            mvno_is_refunded
            reward_log_id
          }
        }
      `,
      variables: {
        id: params.id,
      },
    });

    return { data: queryResult?.getPurchaseLogsById };
  }
}
