import React, { useCallback } from "react";
import {
  List,
  Datagrid,
  TopToolbar,
  sanitizeListRestProps,
  TextField,
  Pagination,
  DateField,
  AutocompleteInput,
  ReferenceInput,
  usePermissions,
  RaRecord,
  FunctionField,
  DeleteWithConfirmButton,
} from "react-admin";
import {
  matchPath,
  useLocation,
  useNavigate,
  Link,
} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { Drawer, Button as MaterialButton, Chip } from "@mui/material";
import { EditPaymentChannel } from "./Edit";
import { CreatePaymentChannel } from "./Create";
import mbaas from "../../../../src/provider/Mbaas";
import { usePersistFilter } from "src/hooks/usePersistFilter";
import { get } from "lodash";

const PaymentChannelPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
);

export interface UpdateProductCsvInterface {
  openDialog: {
    isOpen: boolean;
    dialogType: string;
  };
  setOpenDialog: (props: OpenStateDialogInterface) => any;
}

const ListActions = (props: any) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    openDialog,
    setOpenDialog,
    userData,
    ...rest
  } = props;
  const { isLoading, permissions } = usePermissions();
  usePersistFilter();
  const isAdmin =
    !isLoading &&
    permissions.find((el: string) => el === "Admin" || el === "Super Admin");

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {isAdmin && (
        <>
          <MaterialButton
            color="primary"
            size="small"
            data-testid="create_button"
            component={Link}
            to={{
              pathname: "/services/payment-channel/create",
            }}
          >
            + Create
          </MaterialButton>
        </>
      )}
    </TopToolbar>
  );
};

export interface OpenStateDialogInterface {
  isOpen: boolean;
  dialogType: string;
}

export const PaymentChannelList = () => {
  const [openDialog, setOpenDialog] = React.useState<OpenStateDialogInterface>({
    isOpen: false,
    dialogType: "",
  });

  const { isLoading, permissions } = usePermissions();

  const isAdmin =
    !isLoading &&
    permissions.find((el: string) => el === "Admin" || el === "Super Admin");

  const [userData, setUserData] = React.useState({});
  const location = useLocation();
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  // let { id: idDataRecord } = useParams();

  const getUserData = async () => {
    const res = await mbaas.client.user.get();
    if (res.data) {
      setUserData(res.data);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };

  React.useEffect(() => {
    getUserData();
  }, []);

  const handleCancelDrawer = useCallback(() => {
    navigate(`/services/payment-channel`);
  }, [navigate]);
  const matchCreate = matchPath("/services/payment-channel/create", location.pathname);
  let matchEdit = null;
  if (!location.pathname.endsWith("create"))
    matchEdit = matchPath("/services/payment-channel/:id", location.pathname);

  const choicesChannel = [
    { id: "SEVENTW", name: "7-11" },
    { id: "FAMILYTW", name: "Family Mart" },
    { id: "HILIFETW", name: "Hi-life" },
    { id: "OKMATW", name: "OK Mart" },
    { id: "COIN", name: "Coin" },
  ]

  const PaymentChannelFilter = [
    <ReferenceInput filter={{ isVas: true }} source="id" reference="productCatalogsItemGroup" alwaysOn>
      <AutocompleteInput
        sx={{ width: '220px' }}
        fullWidth
        source="item_group"
        optionText={(choice?: any) => (choice?.id ? choice.item_group : '')}
        label="Item Group"
      />
    </ReferenceInput>,
    <AutocompleteInput
      source="payment_channel"
      label="Channel"
      choices={choicesChannel}
      optionText={(choice: any) => choice.name}
      alwaysOn
    />,
    <AutocompleteInput
      fullWidth
      size="small"
      source="country"
      label="Country"
      optionText={(choice) => choice.name}
      choices={[{ id: "HK", name: "Hong Kong" }, { id: "TW", name: "Taiwan" }]}
      alwaysOn
      sx={{ maxWidth: "150px" }}
    />,
  ];

  return (
    <>
      <List
        empty={false}
        resource="paymentChannel"
        title="Services &rsaquo; Payment Channel"
        filters={PaymentChannelFilter}
        sort={{ field: "created_at", order: "DESC" }}
        actions={
          <ListActions
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            userData={userData}
          />
        }
        perPage={25}
        pagination={<PaymentChannelPagination />}
        sx={{
          flexGrow: 1,
          transition: (theme: any) =>
            theme.transitions.create(["all"], {
              duration: theme.transitions.duration.enteringScreen,
            }),
          marginRight:
            !!matchCreate || !!matchEdit ? "600px" : 0,
        }}
      >
        <Datagrid
          // @ts-ignore
          key={(id) => id}
          bulkActionButtons={false}
        >
          <DateField
            source="created_at"
            label="Date Added"
            showTime
            locales="en-HK"
            options={{
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            }}
          />
          <TextField source="country" label="Country" sortable={false} />
          <TextField source="app" label="App" sortable={false} />
          <TextField source="item_group" label="Item Group" sortable={false} />
          <FunctionField
            label="Payment Channel"
            render={(record: any) => {
              const payment_channel = get(record, 'payment_channel', []);
              return payment_channel.map((channelId: string) => {
                const channel = choicesChannel.find(choice => choice.id === channelId);
                return (
                  <Chip
                    key={channelId}
                    style={{ margin: "0px 5px 5px 5px" }}
                    size="small"
                    label={channel ? channel.name : channelId}
                  />
                );
              });
            }}
          />
          {isAdmin && <FunctionField
            render={(record: RaRecord) => {
              return (
                <div>
                  <MaterialButton
                    color="primary"
                    size="small"
                    data-testid="edit_button"
                    component={Link}
                    to={{
                      pathname: `/services/payment-channel/${record.id}`,
                    }}
                  >
                    <EditIcon fontSize="small" /> Edit
                  </MaterialButton>
                  <DeleteWithConfirmButton
                    confirmTitle="Services &rsaquo; Payment Channel &rsaquo; Delete"
                    redirect={`/services/payment-channel`}
                  />
                </div>
              );
            }}
            source="content"
            label="Action"
            sortable={false}
          />}
        </Datagrid>
      </List>
      <Drawer
        variant="persistent"
        open={!!matchCreate}
        anchor="right"
        onClose={handleCancelDrawer}
        sx={{ zIndex: 100 }}
      >
        {!!matchCreate && <CreatePaymentChannel onCancel={handleCancelDrawer} />}
      </Drawer>
      <Drawer
        variant="persistent"
        open={!!matchEdit}
        anchor="right"
        onClose={handleCancelDrawer}
        sx={{ zIndex: 100 }}
      >
        {!!matchEdit && (
          <EditPaymentChannel
            id={(matchEdit as any).params.id}
            onCancel={handleCancelDrawer}
          />
        )}
      </Drawer>
    </>
  );
};
